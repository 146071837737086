<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('vendorCredits.title') }}
                    </div>
                    <base-badge
                        :label="
                            $t('vendorCredits.itemCount', {
                                count: creditTotal,
                            })
                        "
                    />
                </div>
                <div
                    v-if="unpaidAmount"
                    class="text-sm font-normal text-gray-500"
                >
                    {{
                        $t('vendorCredits.description', {
                            sum: $filters.currency(
                                unpaidAmount,
                                invoiceCurrency
                            ),
                        })
                    }}
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button
                    v-if="$acl.can('create_vendor_credits')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="redirectToCreate"
                >
                    {{ $t('vendorCredits.list.add') }}
                </base-button>
            </div>
        </template>

        <vendor-credits-data-grid
            @total="onCreditTotalReceived"
            table-borderless
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <credit-notes-filter-views />
                    </div>
                    <div class="flex grow gap-x-3 gap-y-1">
                        <credit-notes-filter-search />
                        <credit-notes-filter-slide-out />
                    </div>
                </div>
            </template>
        </vendor-credits-data-grid>
    </base-sticky-heading>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const { t } = useI18n()
useHead({
    title: t('vendorCredits.title'),
})

const router = useRouter()

const invoiceCurrency = CURRENCIES.eur.iso

const creditTotal = ref(0)
const onCreditTotalReceived = (total) => {
    creditTotal.value = total
}

// GET UNPAID AMOUNT
const unpaidAmount = ref(0)
const { execute: getUnpaidAmount, result } = useApi(
    '/api/sale-documents/vendor_credits/unpaid-amount/total',
    'GET'
)

onMounted(async () => {
    try {
        await getUnpaidAmount()
        unpaidAmount.value = result.value?.total_unpaid_amount ?? 0
    } catch (e) {
        console.error(e)
    }
})
// END: GET UNPAID AMOUNT

const redirectToCreate = () => {
    router.push({ name: 'vendor-credits.create' })
}
</script>
