export default {
    vendorCredits: {
        sidebar: {
            label: 'Vendor Credits',
        },
        title: 'Vendor Credits',
        description: 'Sum of unpaid amounts: {sum}',
        itemCount:
            'no vendor credits | 1 vendor credit | {count} vendor credits',
        emptyText: 'No vendor credits found',
        emptySubText: 'Go ahead an create your first vendor credit',
        deleteModal: {
            title: 'Delete vendor credit',
            description: 'Are you sure you want to delete this vendor credit?',
        },
        headerContentTitle: 'Header content',
        footerContentTitle: 'Footer content',
        lineItemsTitle: 'Products and items',
        editExchangeRate: 'Edit Exchange Rate',
        exchangeRateLabel: 'Exchange Rate *',
        recalculateLabel: 'Re-calculate item prices based on this rate',
        moreOptionsLabel: 'More options',
        vatRuleTitle: 'VAT RULE',
        preview: 'Preview',
        proceedBtn: 'Proceed',
        lineItemsExchangeRate: 'Exchange Rate',
        list: {
            add: 'Add Vendor Credit',
            date: 'Date',
            number: 'No.',
            customer: 'Customer',
            status: 'Status',
            amountGross: 'Amount (Gross)',
            balance: 'Balance',
            viewAll: 'View All',
            draft: 'Draft',
            open: 'Open',
            closed: 'Closed',
            allCreditNotes: 'All Vendor Credits',
            searchByNumber: 'Search by vendor credit number',
            filters: {
                netAmount: 'Amount (net)',
            },
        },
        create: {
            title: 'Create Vendor Credit',
        },
        edit: {
            title: 'Edit Vendor Credit',
        },
        dispatch: {
            draftNotice:
                'This is a DRAFT vendor credit. You can take further actions once you approve it.',
            refundBtn: 'Refund',
            applyBtn: 'Apply to bills',
            edit: 'Edit',
            approve: 'Approve',
            transactions: {
                title: 'Refund history',
            },
        },
        applyCreditModal: {
            title: 'Apply Credits to Bills',
            numberColumn: 'Bill number',
            dateColumn: 'Date',
            amountColumn: 'Amount',
            amountDueColumn: 'Amount due',
            creditColumn: 'Amount to credit',
            creditsAppliedText: 'Credits Applied: {amount}',
            remainingCreditText: 'Remaining Credits: {amount}',
            applySuccessMsg: 'Credits have been applied to the bill(s)',
        },
        sendDocumentModal: {
            sendSuccess: 'Vendor credit sent successfully',
        },
        refundModal: {
            title: 'Refund Vendor Credit',
            amountLabel: 'Amount *',
            paymentAccountLabel: 'Payment Account *',
            refundDateLabel: 'Refunded On *',
            referenceLabel: 'Reference',
            descriptionLabel: 'Description',
            refundBtn: 'Refund',
            refundSuccessMessage: 'The amount has been refunded',
        },
        creditedBills: {
            title: 'Bills credited',
            date: 'Date',
            billNo: 'Bill No.',
            amount: 'Amount Credited',
            confirmText:
                'Are you sure about deleting the refund made for this vendor credit?',
            deleteSuccess: 'Credits applied to a bill have been deleted.',
        },
        refundHistory: {
            dateColumn: 'Date',
            refundAmountColumn: 'Amount Refunded',
        },
        generalInfo: {
            customerInfoTitle: 'Vendor credit information',
            customerLabel: 'Customer *',
            customerPlaceholder: 'Search / create contact',
            customerAddressLabel: 'Address',
            customerAddressPlaceholder: 'Enter an address...',
            customerCountryLabel: 'Country *',
            creditNoteHeaderLabel: 'Vendor credit header',
            creditNoteHeaderPlaceholder: 'Vendor credit no. CR-0001',
            creditNoteNumberLabel: 'Vendor credit number *',
            creditNoteNumberPlaceholder: 'CR-0001',
            dateLabel: 'Date *',
            referenceLabel: 'Reference',
            referencePlaceholder: 'CY1245234',
            vendorCreditHeaderPlaceholderValue: 'Vendor credit no. {number}',
            sequenceErrorMessage:
                'Sequence setting for vendor credit not found.',
        },
    },
}
