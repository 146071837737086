<template>
    <base-context-menu>
        <base-context-menu-item
            v-if="$acl.can('update_vendor_credits')"
            icon="line-icons:files:file-attachment-04"
            @click="onEditDocument"
        >
            {{ $t('general.edit') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="$acl.can('delete_vendor_credits')"
            icon="line-icons:general:trash-01"
            @click="onDelete"
        >
            {{ $t('general.delete') }}
        </base-context-menu-item>
    </base-context-menu>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['delete'])

const router = useRouter()

const onEditDocument = () => {
    router.push({
        name: 'vendor-credits.edit',
        params: { id: props.item.id },
    })
}

const onDelete = () => {
    emit('delete', props.item.id)
}
</script>
