<template>
    <div class="py-6">
        <base-section-divider :text="$t('vendorCredits.lineItemsTitle')" />

        <div class="flex cursor-pointer justify-end pb-8 pt-5">
            <base-tooltip
                :bordered="true"
                :padding="false"
                v-if="
                    exchangeRate && convertCurrencyIso !== defaultCurrency?.iso
                "
            >
                <div
                    class="flex items-center justify-end gap-3 text-right text-sm font-medium text-primary-700"
                >
                    {{
                        $t('vendorCredits.lineItemsExchangeRate', {
                            now: $filters.dateCustomFormat(
                                exchangeRate.date,
                                'YYYY-MM-DD'
                            ),
                            fromIso: convertCurrencyIso,
                            toIso: defaultCurrency?.iso,
                            factor,
                        })
                    }}

                    <base-icon
                        variant="inherit"
                        class="h-1/2 w-1/2"
                        size="sm"
                        name="line-icons:editor:pencil-01"
                    />
                </div>

                <template #content="{ close }">
                    <div
                        class="flex items-center justify-between border-b border-gray-300 bg-gray-200 p-2"
                    >
                        <p class="text-sm">
                            {{ $t('vendorCredits.editExchangeRate') }}
                        </p>

                        <base-icon
                            @click="close"
                            name="line-icons:general:x"
                            size="md"
                            variant="gray"
                            class="cursor-pointer"
                        />
                    </div>

                    <div
                        class="max-full flex flex-col items-start justify-start p-3"
                    >
                        <form-number-input
                            v-model="factor"
                            :precision="6"
                            :label="$t('vendorCredits.exchangeRateLabel')"
                            class="mb-2 items-start"
                        />

                        <div class="mb-4">
                            <form-checkbox
                                v-model="shouldRecalculate"
                                :text="$t('vendorCredits.recalculateLabel')"
                            />
                        </div>

                        <base-button
                            variant="primary"
                            :disabled="!factor"
                            @click="
                                () => {
                                    onSaveFactor()
                                    close()
                                }
                            "
                        >
                            {{ $t('general.save') }}
                        </base-button>
                    </div>
                </template>
            </base-tooltip>
        </div>

        <div class="max-w-full overflow-auto pb-2">
            <table class="w-full table-fixed">
                <thead class="border-b border-gray-300 bg-gray-50">
                    <tr class="text-left text-md">
                        <th class="w-60 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.lineItemLabel') }}
                        </th>
                        <th class="w-44 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.accountLabel') }}
                        </th>
                        <th class="w-44 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.quantityLabel') }}
                        </th>
                        <th class="w-44 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.priceLabel') }}
                        </th>
                        <th class="w-48 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.vatLabel') }}
                        </th>
                        <th class="w-32 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.discountLabel') }}
                        </th>
                        <th class="w-40 px-2.5 py-2 font-medium">
                            {{ $t('payment.lineItems.amountLabel') }}
                        </th>
                        <th class="sticky right-0 w-16 px-2.5"></th>
                    </tr>
                </thead>
                <tbody class="border-b border-gray-300">
                    <tr
                        v-for="({ key, value }, idx) in fields"
                        :key="key"
                        class="h-4"
                    >
                        <template
                            v-if="value.type === LINE_ITEM_TYPES.LINE_ITEM"
                        >
                            <td class="py-2 pr-3">
                                <form-field-array-error
                                    :name="`line_items[${key}].label`"
                                    #="{ errorMessage }"
                                >
                                    <form-text-input
                                        v-model="value.label"
                                        :placeholder="
                                            $t(
                                                'payment.lineItems.lineItemNamePlaceholder'
                                            )
                                        "
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                        size="sm"
                                    />
                                </form-field-array-error>
                            </td>
                            <td class="px-1.5 py-2">
                                <form-field-array-error
                                    :name="`line_items[${key}].chart_of_account_id`"
                                    #="{ errorMessage }"
                                >
                                    <form-detail-chart-of-account
                                        v-model="value.chart_of_account_id"
                                        :input-display="
                                            getChartOfAccountName(
                                                value.chart_of_account
                                            )
                                        "
                                        :placeholder="
                                            $t('general.selectAccount')
                                        "
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                        :include-modal="false"
                                        @open-modal="onOpenAddModal(value)"
                                        type="expense"
                                    />
                                </form-field-array-error>
                            </td>
                            <td class="combined-input flex px-1.5 py-2">
                                <form-field-array-error
                                    :name="`line_items[${key}].quantity`"
                                    #="{ errorMessage }"
                                >
                                    <form-number-input
                                        v-model="value.quantity"
                                        :precision="2"
                                        :placeholder="
                                            $t(
                                                'payment.lineItems.quantityPlaceholder'
                                            )
                                        "
                                        size="sm"
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                    />
                                    <form-single-select
                                        class="col-span-2 !w-20"
                                        placeholder="Unit"
                                        :options="units"
                                        option-label="name"
                                        option-value="id"
                                        v-model="value.unit_id"
                                        size="sm"
                                    />
                                </form-field-array-error>
                            </td>

                            <td class="px-1.5 py-2">
                                <form-field-array-error
                                    v-if="
                                        value.type === LINE_ITEM_TYPES.LINE_ITEM
                                    "
                                    :name="`line_items[${key}].net_unit_price`"
                                    #="{ errorMessage }"
                                >
                                    <form-number-input
                                        v-model="value.net_unit_price"
                                        :suffix="' ' + convertCurrencyIso"
                                        :precision="2"
                                        :key="convertCurrencyIso"
                                        input-class="text-right"
                                        :placeholder="
                                            $t(
                                                'payment.lineItems.pricePlaceholder',
                                                {
                                                    iso: convertCurrencyIso,
                                                }
                                            )
                                        "
                                        size="sm"
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                    />
                                </form-field-array-error>
                            </td>
                            <td class="px-1.5 py-2">
                                <form-field-array-error
                                    v-if="
                                        value.type === LINE_ITEM_TYPES.LINE_ITEM
                                    "
                                    :name="`line_items[${key}].tax_rate`"
                                    #="{ errorMessage }"
                                >
                                    <form-single-select
                                        :options="taxRules"
                                        v-model="value.tax_rule_id"
                                        @update:modelValue="
                                            (id, rule) =>
                                                onTaxRuleChange(id, rule, value)
                                        "
                                        :placeholder="
                                            $t(
                                                'payment.lineItems.vatPlaceholder'
                                            )
                                        "
                                        option-value="id"
                                        option-label="name"
                                        size="sm"
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                    />
                                </form-field-array-error>
                            </td>
                        </template>
                        <template v-else>
                            <td class="py-2 pr-3">
                                <form-field-array-error
                                    :name="`line_items[${key}].type`"
                                    #="{ errorMessage }"
                                >
                                    <form-single-select
                                        :options="lineItemTypes"
                                        v-model="value.type"
                                        size="sm"
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                    />
                                </form-field-array-error>
                            </td>

                            <td class="px-1.5 py-2" colspan="4">
                                <form-field-array-error
                                    :name="`line_items[${key}].label`"
                                    #="{ errorMessage }"
                                >
                                    <form-text-input
                                        v-model="value.label"
                                        :placeholder="
                                            $t(
                                                'payment.lineItems.lineItemNamePlaceholder'
                                            )
                                        "
                                        size="sm"
                                        :error-message="errorMessage"
                                        :hide-message="true"
                                    />
                                </form-field-array-error>
                            </td>
                        </template>

                        <td class="combined-input flex px-1.5 py-2">
                            <form-field-array-error
                                :name="`line_items[${key}].discount`"
                                #="{ errorMessage }"
                            >
                                <form-number-input
                                    v-model="value.discount"
                                    :precision="2"
                                    :placeholder="
                                        $t(
                                            'payment.lineItems.discountPlaceholder'
                                        )
                                    "
                                    input-class="text-right"
                                    size="sm"
                                    :error-message="errorMessage"
                                    :hide-message="true"
                                />
                                <div
                                    class="flex-col rounded-r-lg border border-gray-300 px-4 py-2 text-center"
                                >
                                    %
                                </div>
                            </form-field-array-error>
                        </td>

                        <td class="px-1.5px py-2 text-center">
                            {{
                                $filters.currency(
                                    value.type === LINE_ITEM_TYPES.LINE_ITEM
                                        ? netAmount(value)
                                        : getDiscountAmount(value),
                                    convertCurrencyIso
                                )
                            }}
                        </td>
                        <td
                            class="sticky right-0 h-inherit w-16 overflow-y-clip bg-white p-0"
                        >
                            <div
                                class="flex h-full items-center justify-center bg-white shadow-left"
                            >
                                <base-button
                                    outline
                                    size="xl"
                                    icon="line-icons:general:trash-01"
                                    class="!rounded-full border-dashed !p-3 text-gray-400"
                                    variant="gray"
                                    :disabled="isReachMin"
                                    @click="onRemoveItem(idx)"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <base-button-group>
            <base-button
                outline
                variant="default"
                icon="line-icons:general:plus"
                :disabled="isReachMax"
                @click="onAddItem"
            >
                {{ $t('payment.lineItems.addLineItem') }}
            </base-button>

            <base-button
                class="rounded-l-none"
                outline
                variant="default"
                icon="line-icons:general:plus"
                @click="onAddDiscount"
            >
                {{ $t('payment.lineItems.addTotalDiscount') }}
            </base-button>
        </base-button-group>

        <bookeeping-accounts-add-modal
            :show="showAddModal"
            @modal-close="onCloseAddModal"
            @on-finished="onFinished"
        />
    </div>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'
import { LINE_ITEM_TYPES } from '@tenant/modules/tenant/invoices/utils/constants'
import BigNumber from 'bignumber.js'
import { getChartOfAccountName } from '@tenant/utils/helper'
const { defaultCurrency } = useCurrencies()
const contactCurrency = useFieldValue('contact_currency')
const { value: exchangeRate } = useField('exchange_rate')
const { t } = useI18n()

const props = defineProps({
    isEdit: {
        type: Boolean,
        default: false,
    },
    currencyIso: {
        type: String,
        required: false,
    },
})

const convertCurrencyIso = computed(() => {
    if (contactCurrency.value?.iso) {
        return contactCurrency.value?.iso
    }

    if (defaultCurrency.value) {
        return defaultCurrency.value.iso
    }

    return CURRENCIES.eur.iso
})

const { remove, push, fields } = useFieldArray('line_items')

const { isReachMin, isReachMax } = useFormArray({
    fields: fields,
    min: 1,
})

// TAX RULES
const selectedTaxRuleId = useFieldValue('tax_rule_id')
const { taxRules } = useTaxRules(['purchases'])
const selectedTaxRule = computed(() =>
    taxRules.value.find((rule) => rule.id === selectedTaxRuleId.value)
)

const { chartOfAccounts } = useFlatChartOfAccounts(2, 'expenses')

onMounted(() => {
    if (!fields.value.length && !props.isEdit) {
        onAddItem()
    }
})

// Wait until chartOfAccounts is loaded, then set the default COA for line items
watch(
    () => chartOfAccounts.value.length,
    () => {
        const salesId = chartOfAccounts.value.find(
            (item) => item.slug === 'purchases'
        )?.id

        fields.value.map(({ value }) => {
            if (!value.chart_of_account_id) {
                value.chart_of_account_id = salesId
            }
        })
    }
)
// END TAX RULES

const onAddItem = () => {
    push({
        label: null,
        quantity: 1,
        unit_id: null,
        net_unit_price: 0,
        chart_of_account_id: chartOfAccounts.value.find(
            (item) => item.slug === 'sales'
        )?.id,
        tax_rule_id: selectedTaxRuleId.value,
        tax_rate: selectedTaxRule.value?.tax_rate
            ? selectedTaxRule.value.tax_rate
            : 0,
        discount: 0,
        amount_net_total: 0,
        type: LINE_ITEM_TYPES.LINE_ITEM,
        discount_type: 'percent',
    })
}

const { totalAmountBeforeDiscount } = useTotalSummary(fields)

const onAddDiscount = () => {
    push({
        label: t('payment.lineItems.discountLabel'),
        quantity: 1,
        unit_id: null,
        net_unit_price: 0,
        chart_of_account_id: null,
        tax_rule_id: null,
        tax_rate: 0,
        discount: 0,
        amount_net_total: 0,
        type: LINE_ITEM_TYPES.DISCOUNT,
        discount_type: 'percent',
    })
}

const onRemoveItem = (idx) => {
    remove(idx)
}

// Calculate the NET amount of the lineItem
const netAmount = (lineItem) => {
    const netPrice = lineItem.quantity * lineItem.net_unit_price
    const finalPrice = netPrice - netPrice * (lineItem.discount / 100)
    lineItem.amount_net_total = finalPrice
    return finalPrice
}

const getDiscountAmount = (lineItem) => {
    const netAmount =
        (totalAmountBeforeDiscount.value * lineItem.discount) / 100
    lineItem.amount_net_total =
        lineItem.type === LINE_ITEM_TYPES.DISCOUNT ? -netAmount : netAmount

    return lineItem.amount_net_total
}

// FACTOR BASED ON EXCHANGE RATE
const factor = computed({
    get() {
        if (!exchangeRate.value?.rate) {
            return 1
        }

        return new BigNumber(1 / exchangeRate.value?.rate)
            .decimalPlaces(6)
            .toNumber()
    },
    // setter
    set(newValue) {
        exchangeRate.value.rate = 1 / newValue
    },
})

const shouldRecalculate = ref(false)
const onSaveFactor = () => {
    if (shouldRecalculate.value) {
        fields.value.forEach((item) => {
            if (item.value.type === LINE_ITEM_TYPES.LINE_ITEM) {
                item.value.net_unit_price =
                    item.value.net_unit_price * exchangeRate.value.rate
            }
        })
    }
}

// Units
const { units } = useUnits()

const lineItemTypes = ref([
    {
        label: t('payment.lineItemsTypes.discount'),
        value: LINE_ITEM_TYPES.DISCOUNT,
    },
    {
        label: t('payment.lineItemsTypes.surcharge'),
        value: LINE_ITEM_TYPES.SURCHARGE,
    },
])

const onTaxRuleChange = (taxRuleId, taxRule, lineItem) => {
    lineItem.tax_rate = taxRuleId ? taxRule.tax_rate : 0
}

// COA MODAL
const { showAddModal, onCloseAddModal, onOpenAddModal, onFinished } =
    useCOAModal()
// END: COA MODAL
</script>

<style scoped>
:deep(.popper #arrow::before) {
    background: theme('colors.gray.200');
}

:deep(.popper:hover #arrow::before) {
    background: theme('colors.gray.200');
}
</style>
