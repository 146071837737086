<template>
    <base-modal close @modal-close="closeModal" :show="show" size="sm">
        <template #modal-icon>
            <div class="flex">
                <base-feature-icon
                    variant="danger"
                    name="line-icons:alerts-&-feedback:alert-circle"
                />
            </div>
        </template>

        <template #modal-header>
            {{ $t('vendorCredits.deleteModal.title') }}
        </template>

        <p
            class="mt-2 text-sm font-normal text-gray-500"
            v-html="$t('vendorCredits.deleteModal.description')"
        ></p>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    variant="danger"
                    :loading="loading"
                    @click="onConfirm"
                >
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    creditNoteId: {
        type: String,
        required: true,
    },
    show: {
        type: Boolean,
        default: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = () => {
    emit('modal-confirm', props.creditNoteId)
}
</script>
