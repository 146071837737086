const navigation = [
    {
        id: 'sas-purchases-vendor-credits',
        path: 'vendor-credits',
        label: 'sidebar.purchases.vendorCredits',
        parent: 'sas-purchases',
        position: 3,
        permission: 'view_vendor_credits',
    },
]

export default navigation
