<template>
    <base-modal
        size="xl"
        class="w-[960px]"
        close
        @modal-close="$emit('modal-close')"
        :show="show"
        has-separator
    >
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('vendorCredits.applyCreditModal.title') }}
            </h4>
        </template>

        <data-grid
            :columns="columns"
            :data-source="lineItems"
            :loading="loading"
            :key="updateKey"
        >
            <template #column-number="{ item }">
                {{ item.document_number }}
            </template>

            <template #column-date="{ item }">
                {{ $filters.dateHumanFormat(item.date_of_document) }}
            </template>

            <template #column-amount="{ item }">
                {{
                    $filters.symbolCurrency(
                        item.amount_gross_total,
                        item.currency
                    )
                }}
            </template>

            <template #column-unpaidAmount="{ item }">
                {{ $filters.symbolCurrency(item.unpaid_amount, item.currency) }}
            </template>
            <template #column-creditAmount="{ item }">
                <form-number-input
                    :model-value="item.credit_amount"
                    @change="onChangeAmount($event, item)"
                    size="sm"
                    :precision="2"
                />
            </template>
        </data-grid>

        <p class="mb-2.5 mt-5 text-right text-sm font-medium text-gray-900">
            {{
                $t('vendorCredits.applyCreditModal.creditsAppliedText', {
                    amount: $filters.numberFormat(creditedAmount),
                })
            }}
        </p>

        <p class="text-right font-medium text-gray-900">
            {{
                $t('vendorCredits.applyCreditModal.remainingCreditText', {
                    amount: $filters.numberFormat(remainingCredit),
                })
            }}
        </p>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="$emit('modal-close')"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    :disabled="!creditedAmount"
                    :loading="isApplyingCredit"
                    @click="onApplyCredit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { BILL_STATUS } from '@tenant/modules/tenant/purchases-bill/utils/constants'
import { filter, map, fromPairs } from 'lodash-es'
import { useCreditCalculator } from '@tenant/composables/use-credit-calculator'

const emit = defineEmits(['modal-close', 'modal-save'])
const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    creditNote: {
        type: [Object, null],
        default: () => ({}),
    },
})

const { successNotify, errorNotify } = useNotification()
const { t } = useI18n()

const loading = ref(false)
const { useApiSearch, entities } = useApiFactory('bill')

const lineItems = ref([])

onMounted(async () => {
    try {
        loading.value = true
        await useApiSearch(
            {
                limit: 10,
                filter: {
                    status: ['in', [BILL_STATUS.OPEN, BILL_STATUS.PART_PAID]],
                    contact_id:
                        props.creditNote?.sale_document?.contact.contact_id,
                },
            },
            true,
            true
        ).execute()

        if (!entities.value?.data?.length) return

        entities.value.data.forEach((entity) => {
            lineItems.value.push({
                ...entity,
                credit_amount: 0,
            })
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        loading.value = false
    }
})

const columns = [
    {
        property: 'number',
        label: t('vendorCredits.applyCreditModal.numberColumn'),
    },
    {
        property: 'date',
        label: t('vendorCredits.applyCreditModal.dateColumn'),
    },
    {
        property: 'amount',
        label: t('vendorCredits.applyCreditModal.amountColumn'),
    },
    {
        property: 'unpaidAmount',
        label: t('vendorCredits.applyCreditModal.amountDueColumn'),
    },
    {
        property: 'creditAmount',
        label: t('vendorCredits.applyCreditModal.creditColumn'),
        headerCellClass: 'w-44',
    },
]

const { creditedAmount, remainingCredit, updateKey, onChangeAmount } =
    useCreditCalculator(
        props.creditNote?.sale_document?.unpaid_amount,
        lineItems.value,
        true
    )

const { execute: executeApplyCredit, loading: isApplyingCredit } = useApi(
    '/api/vendor-credit/{id}/apply-bills',
    'POST'
)
const onApplyCredit = async () => {
    const request = {
        bills: fromPairs(
            map(
                filter(lineItems.value, (item) => item.credit_amount > 0),
                (item) => [item.id, item.credit_amount]
            )
        ),
    }
    try {
        await executeApplyCredit({
            params: {
                id: props.creditNote.id,
            },
            data: request,
        })

        successNotify({
            text: t('vendorCredits.applyCreditModal.applySuccessMsg'),
        })

        emit('modal-save')
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
}
</script>
