<template>
    <base-modal size="md" close @modal-close="closeModal" :show="show">
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('vendorCredits.refundModal.title') }}
            </h4>
        </template>

        <form-control-group>
            <form-number-input
                v-model="amount"
                :error-message="errorAmount"
                :label="$t('vendorCredits.refundModal.amountLabel')"
                :suffix="' ' + currencyIso"
                :precision="2"
            />

            <form-date-picker
                v-model="paymentDate"
                :error-message="errorPaymentDate"
                :label="$t('vendorCredits.refundModal.refundDateLabel')"
                single
                auto-apply
            />

            <form-single-select
                v-model="paymentAccountId"
                :error-message="errorPaymentAccountId"
                :label="$t('vendorCredits.refundModal.paymentAccountLabel')"
                :options="paymentAccounts"
                :show-clear="false"
                option-label="name"
                option-value="id"
            />

            <form-text-input
                v-model="reference"
                :error-message="errorReference"
                :label="$t('vendorCredits.refundModal.referenceLabel')"
            />

            <form-rich-text-editor
                v-model="description"
                :error-message="errorDescription"
                :label="$t('vendorCredits.refundModal.descriptionLabel')"
            />
        </form-control-group>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width :loading="loading" @click="onConfirm">
                    {{ $t('vendorCredits.refundModal.refundBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const emit = defineEmits(['modal-close', 'on-refunded'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    creditNote: {
        type: [Object, null],
        default: () => ({}),
    },
})

const { paymentAccounts } = usePaymentAccounts()
const { successNotify, errorNotify } = useNotification()
const { t } = useI18n()

const { handleSubmit, setErrors } = useForm()
const { value: bookedId } = useField('booked_id', undefined, {
    initialValue: props.creditNote?.id,
})
const { value: paymentAccountId, errorMessage: errorPaymentAccountId } =
    useField('payment_account_id')
const { value: amount, errorMessage: errorAmount } = useField(
    'amount',
    undefined,
    {
        initialValue: props.creditNote?.sale_document?.unpaid_amount,
    }
)
const { value: paymentDate, errorMessage: errorPaymentDate } = useField(
    'payment_date',
    undefined,
    {
        initialValue: dayjsFormat(now(), DATE_SERVER_FORMAT),
    }
)
const { value: reference, errorMessage: errorReference } = useField('reference')
const { value: description, errorMessage: errorDescription } =
    useField('description')

watch(
    () => props.creditNote,
    (newVal) => {
        if (newVal) {
            paymentAccountId.value = null
            bookedId.value = newVal.id
            amount.value = newVal.sale_document.unpaid_amount
        }
    }
)

const closeModal = () => {
    emit('modal-close')
}

const { useApiCreate } = useApiFactory('vendor-credit-refund')
const { execute, loading } = useApiCreate()

const onConfirm = handleSubmit(async (values) => {
    try {
        await execute(values)

        successNotify({
            text: t('vendorCredits.refundModal.refundSuccessMessage'),
        })

        emit('on-refunded')
    } catch ({ errors, errorMessage }) {
        setErrors(errors)
        errorNotify({ text: errorMessage })
    }
})

const currencyIso = computed(() => {
    return props.creditNote?.sale_document?.currency?.iso
})
</script>
