<template>
    <data-grid
        :data-source="creditNotes.data"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="$t('vendorCredits.emptyText')"
        :empty-sub="$t('vendorCredits.emptySubText')"
        identify-field="id"
        @row-click="redirectToDetail"
        v-bind="$attrs"
    >
        <template v-if="$slots['header']" #header>
            <slot name="header" />
        </template>

        <template v-if="$slots['filter']" #filter>
            <slot name="filter" />
        </template>

        <template #column-status="{ item }">
            <partial-status :status="item.sale_document.status" variant="small">
                <template #text>
                    {{ item.sale_document.status.toUpperCase() }}
                </template>
            </partial-status>
        </template>

        <template #column-customer="{ item }">
            <div class="flex flex-col">
                <div
                    class="whitespace-nowrap text-sm font-normal text-gray-900"
                >
                    <slot name="title">{{
                        getContactName(item.sale_document.contact)
                    }}</slot>
                </div>
                <div
                    class="whitespace-nowrap text-sm font-normal text-gray-500"
                >
                    <slot name="description">
                        {{ item.vendor_credit_header }}</slot
                    >
                </div>
            </div>
        </template>

        <template #column-date="{ item }">
            {{ $filters.dateShortFormat(item.date_of_vendor_credit) }}
        </template>

        <template #column-number="{ item }">
            {{ item.sale_document.number }}
        </template>

        <template #column-amountNet="{ item }">
            {{
                $filters.currency(
                    item.sale_document.amount_net_total,
                    item.sale_document?.currency?.iso ?? defaultCurrency
                )
            }}
        </template>

        <template #column-amountGross="{ item }">
            {{
                $filters.currency(
                    item.sale_document.amount_gross_total,
                    item.sale_document?.currency?.iso ?? defaultCurrency
                )
            }}
        </template>

        <template #column-balance="{ item }">
            {{
                $filters.currency(
                    item.sale_document.unpaid_amount,
                    item.sale_document?.currency?.iso ?? defaultCurrency
                )
            }}
        </template>

        <template #column-action="{ item }">
            <vendor-credits-context-menu
                :item="item"
                @delete="onShowDeleteModal(item)"
            />
        </template>

        <template #footer>
            <grid-pagination
                :total="creditNotes.last_page"
                :page="creditNotes.current_page"
            />
        </template>

        <template v-if="!hasFilter" #empty-actions>
            <slot name="empty-actions">
                <base-button
                    v-if="$acl.can('create_vendor_credits')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="redirectToCreate"
                >
                    {{ $t('vendorCredits.list.add') }}
                </base-button>
            </slot>
        </template>
    </data-grid>

    <vendor-credits-delete-modal
        v-if="entity?.id"
        :credit-note-id="entity?.id"
        :loading="isDeleting"
        :show="!!entity"
        @modal-close="setEntity(null)"
        @modal-confirm="onDeleteCreditNote"
    />

    <credit-notes-delete-failure-modal
        :show="showCannotDeleteModal"
        @modal-close="showCannotDeleteModal = false"
    />
</template>

<script setup>
import { CONTACT_RELATIONSHIP_TYPES, CURRENCIES } from '@tenant/utils/constants'

const { t } = useI18n()
const router = useRouter()

const emit = defineEmits(['total'])
const props = defineProps({
    params: {
        type: Object,
        default: () => ({}),
    },
})

const {
    entities: creditNotes,
    useApiSearch,
    useApiDelete,
    setEntity,
    entity,
} = useApiFactory('sale-documents/vendor_credits')
const {
    execute: searchCreditNote,
    loading: loadingSearch,
    executeRefreshSearch: refreshCreditNotes,
} = useApiSearch({ ...props.params }, true)

onMounted(async () => {
    await searchCreditNote()
    emit('total', creditNotes.value.total)
})

const defaultCurrency = CURRENCIES.eur.iso

const columns = [
    {
        property: 'date',
        label: t('vendorCredits.list.date'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'number',
        label: t('vendorCredits.list.number'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'customer',
        label: t('vendorCredits.list.customer'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'status',
        label: t('vendorCredits.list.status'),
    },
    {
        property: 'amountGross',
        label: t('vendorCredits.list.amountGross'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'balance',
        label: t('vendorCredits.list.balance'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
]

const redirectToCreate = () => {
    router.push({ name: 'vendor-credits.create' })
}

const getContactName = (contact) => {
    if (!contact) return null

    return contact.type === CONTACT_RELATIONSHIP_TYPES.person
        ? `${contact.first_name} ${contact.last_name}`
        : contact.company
}

// DELETE CREDIT NOTE
const isDeleting = ref(false)
const { execute: deleteCreditNote } = useApiDelete()
const { errorNotify } = useNotification()

const onDeleteCreditNote = async (id) => {
    try {
        isDeleting.value = true
        await deleteCreditNote(id)
        await refreshCreditNotes()
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        isDeleting.value = false
        setEntity(null)
    }
}
// END: DELETE CREDIT NOTE

// PRE-FETCH CONTACTS FOR FILTER PANEL
import { QueryClient } from '@tanstack/vue-query'
const { execute: executeSearchContacts } = useApiFactory(
    'contacts'
).useApiSearch({}, false)

const queryClient = new QueryClient()
queryClient.prefetchQuery({
    queryKey: ['contacts'],
    queryFn: () =>
        executeSearchContacts({
            queries: { page: 1, limit: 500 },
        }),
    staleTime: 60 * 1000,
})
// END: PRE-FETCH CONTACTS FOR FILTER PANEL
const route = useRoute()
const hasFilter = computed(() => route.query.q || route.query.filter)

const redirectToDetail = (id) => {
    router.push({ name: 'vendor-credits.dispatch', params: { id } })
}

const showCannotDeleteModal = ref(false)
const onShowDeleteModal = (item) => {
    if (item.bills.length) {
        showCannotDeleteModal.value = true
    } else {
        setEntity(item)
    }
}
</script>
