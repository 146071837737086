<template>
    <base-sticky-heading
        :title="$t('vendorCredits.create.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="onCancel"
                    :disabled="isSaving"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    outline
                    variant="default"
                    @click="onPreview"
                    icon="line-icons:general:search-lg"
                    :loading="isSaving"
                >
                    {{ $t('vendorCredits.preview') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('vendorCredits.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <vendor-credits-create-information />
            <vendor-credits-create-header-content
                :default-content="headerContent"
            />
            <vendor-credits-create-line-items
                :is-edit="!!$route.query.invoice_id"
            />
            <vendor-credits-create-footer-content
                :default-content="footerContent"
            />
            <vendor-credits-create-more-options />
            <vendor-credits-create-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'
import { shortServerFormat } from '@tenant/utils/day'
const emitter = useEmitter()

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setErrors, handleSubmit, setFieldValue } = useForm()
useField('invoice_id', undefined, {
    initialValue: route.query.invoice_id,
})

useHead({
    title: t('vendorCredits.create.title'),
})

onMounted(async () => {
    try {
        textTemplates.value = await getDefaultTextTemplate(
            'document',
            'vendor_credits'
        )

        emitter.emit('set-loading', false)
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({ name: 'vendor-credits.list' })
}

const onPreview = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveCreditNote(values)

    if (data?.id) {
        await router.push({
            name: 'vendor-credits.dispatch',
            params: { id: data.id },
        })
    }
})

const onProceed = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveCreditNote(values)

    if (data?.id) {
        await router.push({
            name: 'vendor-credits.dispatch',
            params: { id: data.id },
        })
    }
})

const store = useStore()
const currentUser = computed(() => store.getters.user)
const { useApiCreate } = useApiFactory('sale-documents/vendor_credits')
const { execute, loading: isSaving, code, parameters } = useApiCreate()
const { defaultCurrency } = useCurrencies()

const saveCreditNote = async (values, status = CREDIT_NOTE_STATUS.DRAFT) => {
    try {
        return await execute({
            ...values,
            currency_id: values.currency_id ?? defaultCurrency.value.value,
            user_id: currentUser.value.id,
            status,
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

// Get default text template for credit note
const { getDefaultTextTemplate } = useTextTemplate()
const textTemplates = ref(null)

const footerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'footer_content'
        )?.content ?? ''
    )
})

const headerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'header_content'
        )?.content ?? ''
    )
})

const contactCurrency = useFieldValue('contact_currency')
const { setValue: setExchangeRate } = useField('exchange_rate')
watchEffect(() => {
    if (!contactCurrency.value) {
        return
    }

    const { rate, rate_updated_at } = contactCurrency.value
    setExchangeRate({
        rate,
        date: shortServerFormat(rate_updated_at),
    })
})

// RETRIEVE LINE ITEMS FROM INVOICE
const { useApiGet, entity: invoice } = useApiFactory('sale-documents/invoices')
const { execute: executeGet } = useApiGet()

onMounted(async () => {
    if (!route.query.invoice_id) return

    await executeGet(route.query.invoice_id)

    // Convert invoice line items to credit note line items
    let remainingAmount = creditGross.value
    const lineItems = invoice.value.line_items.map((item) => {
        const { tax_rate, amount_net_total, discount, quantity } =
            item.sale_document_line_item
        // Calculate line item gross amount based on net amount
        const lineItemGross = amount_net_total * (1 + tax_rate / 100)

        // If there's remaining credit amount, deduct it from the current line item
        let lineItemAmount = lineItemGross - remainingAmount

        // If the remaining amount is more than the line item amount, set it as the next remaining amount
        // Else, set the remaining amount to 0
        if (lineItemAmount < 0) {
            remainingAmount = Math.abs(lineItemAmount)
            lineItemAmount = 0
        } else {
            remainingAmount = 0
        }

        return {
            ...item.sale_document_line_item,
            net_unit_price: lineItemAmount / (1 + tax_rate / 100) / quantity,
            tax_rate: tax_rate,
            discount: discount,
            quantity: quantity,
        }
    })

    setFieldValue('line_items', lineItems)
    setFieldValue('contact_id', invoice.value.sale_document.contact.contact_id)
    setFieldValue('contact', invoice.value.sale_document.contact.contact)
})

const creditGross = computed(() => {
    if (!invoice.value) return 0

    const { amount_gross_total, unpaid_amount } = invoice.value.sale_document

    return amount_gross_total - unpaid_amount
})
// END: RETRIEVE LINE ITEMS FROM INVOICE
</script>
