<template>
    <div class="relative border-t pt-6">
        <!-- AC-234: Support multi currency -->
        <div class="mb-5 flex border-b text-xl">
            <div class="grow">{{ $t('createInvoice.netAmount') }}</div>
            <div>
                {{ $filters.currency(totalNetAmount, invoiceCurrency) }}
            </div>
        </div>
        <div
            class="mb-5 flex border-b text-xl"
            v-for="(value, key) in vatAmounts"
            :key="key"
        >
            <div class="grow">{{ $t('createInvoice.vat', { n: key }) }}</div>
            <div>
                {{ $filters.currency(value, invoiceCurrency) }}
            </div>
        </div>
        <div class="flex text-2xl font-bold">
            <div class="grow">{{ $t('createInvoice.total') }}</div>
            <div class="text-xl">
                {{ $filters.currency(totalAmount, invoiceCurrency) }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const { fields } = useFieldArray('line_items')
const { value: amountNet } = useField('amount_net_total')
const { value: amountGross } = useField('amount_gross_total')
const contactCurrency = useFieldValue('contact_currency')
const { totalAmount, totalNetAmount, vatAmounts } = useTotalSummary(fields)
const { defaultCurrency } = useCurrencies()

// Update `amount_net_total` and `amount_gross_total` fields based on computed values
watch(totalAmount, () => (amountGross.value = totalAmount.value), {
    immediate: true,
})
watch(totalNetAmount, () => (amountNet.value = totalNetAmount.value), {
    immediate: true,
})

const invoiceCurrency = computed(() => {
    if (contactCurrency.value?.iso) {
        return contactCurrency.value?.iso
    }

    if (defaultCurrency.value) {
        return defaultCurrency.value.iso
    }

    return CURRENCIES.eur.iso
})
</script>
